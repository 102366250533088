import React, { useEffect, useState } from "react";
import Title from "../../extras/Title";
import { getTicket, reopenTicket } from "../../redux/slice/ticketSlice";
import Table from "../../extras/Table";
import AvtarImg from "../../../assets/images/AvtarImg.png";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Pagination from "../../extras/Pagination";
import Searching from "../../extras/Searching";
import { IoTrashSharp } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { TiEdit } from "react-icons/ti";
import { GrFormView } from "react-icons/gr";
import { GoIssueReopened } from "react-icons/go";
import { openDialog } from "../../redux/slice/dialogueSlice";
import { FiPlus } from "react-icons/fi";
import { baseURL } from "../../utils/config";
import Button from "../../extras/Button";
import DeveloperDialogue from "./dialogue/DeveloperDialogue";
import { warning } from "../../utils/Alert";
import { IoIosCopy } from "react-icons/io";
import TicketDialogue from "./dialogue/TicketDialogue";
import TicketView from "./dialogue/TicketView";
import { Success } from "../../api/toastServices";
import Analytics from "../../extras/Analytics";
import TicketDialogueDeveloperEdit from "./dialogue/TicketDialogueDeveloperEdit";
import Select from "react-select";
import { getAllDeveloper } from "../../redux/slice/developerSlice";

export default function TicketPage() {
  const [data, setData] = useState("");
  const [search, setSearch] = useState("All");
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("All");
  const [status, setStatus] = useState("All");
  const [statusLabel, setStatusLabel] = useState("All");
  const [devLabel, setDevLabel] = useState("All");
  const [dev, setDev] = useState("All");
  const [endDate, setEndDate] = useState("All");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { ticketTotalAdmin, ticketAdminData, developerAdminData } = useSelector(
    (state) => state.ticketAdmin
  );

  const TruncatedText = ({ text, maxLength }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncated = () => {
      setIsTruncated(!isTruncated);
    };

    return (
      <span>
        {isTruncated ? `${text.slice(0, maxLength)}...` : text}
        <span
          onClick={toggleTruncated}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {isTruncated ? " Show More" : " Show Less"}
        </span>
      </span>
    );
  };

  const dispatch = useDispatch();

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", AvtarImg);
    });
  });

  useEffect(() => {
    setData(ticketAdminData);
  }, [ticketAdminData]);

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      startDate: startDate,
      endDate: endDate,
      status: status,
      search: search,
      developer: dev,
    };
    dispatch(getTicket(payload));
    const startLimit = {
      start: 1,
      limit: 100,
    };
    dispatch(getAllDeveloper(startLimit));
  }, [page, rowsPerPage, startDate, endDate, search, status, dev]);

  const options = [
    { value: "All", label: "All" },
    { value: 1, label: "Open" },
    { value: 2, label: "Closed" },
    { value: 3, label: "Reopened" },
  ];

  const allDev = developerAdminData.map((dev) => ({
    label: dev.name,
    value: dev._id,
  }));

  allDev.unshift({ label: "All", value: "All" });

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setRowsPerPage(value);
  };
  const handleFilterData = (filteredData) => {
    setSearch(filteredData);
  };

  const hadnleCopyText = (ticketId) => {
    navigator.clipboard.writeText(ticketId);
    Success("Ticket copied");
  };

  const handleReopenTicket = (row) => {
    
    const payload = {
      ticketId: row?.ticketId,
      client: row?.client?._id,
    };
    dispatch(reopenTicket(payload));
  };

  const handleDelete = (id) => {
    warning("Delete").then((result) => {
      if (result.isConfirmed) {
        // dispatch(deleteDeveloper(id));
      }
    });
  };

  const handleStatus = (e) => {
    setStatus(e.value);
    setStatusLabel(e.label);
  };

  const handleDev = (e) => {
    setDev(e.value);
    setDevLabel(e.label);
  };

  const hadnleViewTicket = (data) => {
    dispatch(openDialog({ type: "ticketView", data: data }));
  };

  const ticketTable = [
    {
      Header: "No",
      body: "no",
      Cell: ({ index }) => (
        <span>{(page - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Ticket Id",
      body: "ticketId",
      Cell: ({ row }) => (
        <div className="copyText">
          <span>{row?.ticketId}</span>
          {/* <Button
                        onClick={() => hadnleCopyText(row?.ticketId)}
                        bIcon={<IoIosCopy />}
                    /> */}
        </div>
      ),
    },
    {
      Header: "Client Name",
      body: "clientName",
      Cell: ({ row }) => <span>{row?.client?.name}</span>,
    },
    {
      Header: "Developer Name",
      body: "developerName",
      Cell: ({ row }) => <span>{row?.developer?.name}</span>,
    },
    {
      Header: "App Name",
      body: "appName",
      Cell: ({ row }) => <span>{row?.appName}</span>,
    },
    {
      Header: "Issue Description",
      body: "issueDescription",
      Cell: ({ row }) => (
        <TruncatedText
          text={row?.issueDescription?.replace(/<\/?[^>]+(>|$)/g, "")}
          maxLength={30}
        />
      ),
    },
    {
      Header: "Status",
      body: "status",
      Cell: ({ row }) => (
        <div className="statusButton d-flex justify-content-center">
          {row?.status === 1 ? (
            <span className="openTicket  badge">Open</span>
          ) : row?.status === 2 ? (
            <span className="closedTicket badge">Closed</span>
          ) : (
            <span className="reopenTicket badge">Reopen</span>
          )}
        </div>
      ),
    },

    {
      Header: "Reopen",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          <Button
            className={"reOpen"}
            disabled={row?.status === 2 ? false : true}
            onClick={() => handleReopenTicket(row)}
            bIcon={<GoIssueReopened />}
            style={{ opacity: `${row?.status === 2 ? "1" : "0.6"}` }}
          />
        </div>
      ),
    },
    {
      Header: "View",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          <Button
            className={" viewButton"}
            onClick={() => hadnleViewTicket(row)}
            bIcon={<GrFormView />}
            style={{}}
          />
        </div>
      ),
    },
    {
      Header: "Date",
      body: "Date",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM, YYYY")}</span>
      ),
    },
  ];

  const CustomIndicator = () => {
    return (
      <div className="css-1xc3v61-indicatorContainer p-0">
        <span style={{ color: "white" }}>Status</span>
      </div>
    );
  };

  const CustomIndicator2 = () => {
    return (
      <div className="css-1xc3v61-indicatorContainer p-0">
        <span style={{ color: "white" }}>Dev</span>
      </div>
    );
  };
  return (
    <div className="TicketPage">
      <Title name={"Ticket"} total={ticketTotalAdmin} totalShow={true} />
      <div className="row mt-3 mb-1">
        <div className="col-12 col-md-8 d-flex align-items-center anlyticsButton">
          <Button
            text={"New"}
            className={"newButton"}
            style={{ marginRight: "16px" }}
            onClick={() => dispatch(openDialog({ type: "ticket" }))}
            bIcon={<FiPlus />}
          />
          <Analytics
            dayAnalyticsShow={true}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
          />
          <Select
            className="ms-3 select-status"
            value={status}
            onChange={(e) => handleStatus(e)}
            options={options}
            placeholder={statusLabel}
            defaultValue={options[0]?.value}
            components={{ DropdownIndicator: CustomIndicator }}
          />
          <Select
            className="ms-3 select-status"
            value={dev}
            onChange={(e) => handleDev(e)}
            options={allDev}
            placeholder={devLabel}
            defaultValue={allDev[0]?.value}
            components={{ DropdownIndicator: CustomIndicator2 }}
          />
        </div>
        <div className="col-12 col-md-4 mt-2">
          <Searching
            type="server"
            data={ticketAdminData}
            button={true}
            setData={setSearch}
            allData={ticketAdminData}
            serverSearching={handleFilterData}
          />
        </div>
      </div>
      <div className="mt-0">
        <div className="row">
          <div className="col-6"></div>
        </div>
        <Table
          data={data}
          mapData={ticketTable}
          serverPerPage={rowsPerPage}
          serverPage={page}
          type={"server"}
        />
        <Pagination
          type={"server"}
          activePage={page}
          rowsPerPage={rowsPerPage}
          userTotal={ticketTotalAdmin}
          setPage={setPage}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
        {dialogueType === "ticket" && <TicketDialogue />}
        {dialogueType === "ticketView" && <TicketView />}
      </div>
    </div>
  );
}
